//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SectionTitle from "@/components/common/section/SectionTitle";
import widgetLayoutMixin from "/mixins/widgetLayoutMixin";

export default {
    name: "Main",
    components: { SectionTitle },
    mixins: [widgetLayoutMixin],
    data() {
        return {
            dataToSendInGa: [],
            gaSendTimeout: null
        };
    },
    computed: {
        sortList() {
            return (this.innerData?.items ?? []);
        }
    },
    methods: {
        oneItemViewed(isVisible, entry, item, position) {
            if (!isVisible) {
                return;
            }
            this.dataToSendInGa.push(Object.assign({}, item, {
                position
            }));
            this.gaSendTimeout && clearTimeout(this.gaSendTimeout);
            this.gaSendTimeout = setTimeout(() => {
                this.$ga.viewPromotion((this.dataToSendInGa ?? []).map((el) => ({
                    name: this.widgetInfo.name,
                    id: this.widgetInfo.id,
                    creative: (el?.text ?? ""),
                    position: el.position
                })));
                this.dataToSendInGa = [];
            }, 50);
        },
        oneItemClicked(itemInfo, position) {
            this.$ga.clickPromotion([{
                name: this.widgetInfo.name,
                id: this.widgetInfo.id,
                creative: (itemInfo?.text ?? ""),
                position: position
            }]);
        },
        onMouseUp(e, itemInfo, position) {
            if (e.button === 1) {
                this.oneItemClicked(itemInfo, position);
            }
        }
    },
};
