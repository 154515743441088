//
//
//
//
//
//

export default {
    name: "SectionFullWidth",
}
