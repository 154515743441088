//
//
//
//
//
//

import mainLayout from './layouts/Main'
import SectionContainer from "@/components/common/section/SectionContainer";
import widgetMixin from "@/mixins/widgetMixin";
export default {
    name: "ForWhom",
    components: {SectionContainer, mainLayout},
    mixins:[widgetMixin],
    computed: {
        showedWidget() {
            return (this?.widgetInfo?.data?.items ?? []).length === 3
        },
    }
}
