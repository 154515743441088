//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";
import SectionProduct from "@/components/common/section/SectionProduct";
import widget_main_page_banner from "@/widgets/mainHomeBanner/Index";
import widget_keyword_component from "@/widgets/popularCategories/Index";
import content_product_categories from "@/widgets/forWhom/Index";
import widget_visual_navigation from "@/widgets/categoriesTabs/Index";
import content_teaser_carousel from "@/widgets/compilationCarousel/Index";
import widget_teaser_card from "@/widgets/compilationCard/Index";
import widget_bestsellers from "@/widgets/productCarousel/Index";
import widget_novelty from "@/widgets/productCarousel/Index";
import widget_we_recommend from "@/widgets/productCarousel/Index";
import widget_recently_viewed from "@/widgets/productCarousel/Index";
import widget_choice_other_buyers from "@/widgets/productCarousel/Index";
import content_teaser_small from "@/widgets/teaserCardSmall/Index";
import widget_keyword_component_main from "@/widgets/storeSubcategories/Index";

export default {
    name: "ProductWidgets",
    computed: {
        ...mapGetters({
            routeInfo: 'dynamicPage/getRouteInfo',
            macroPageStructure: 'dynamicPage/getMacroPageStructure',
            microPageStructure: 'dynamicPage/getMicroPageStructure',
            pageType: 'dynamicPage/pageType'
        }),

        filteredWidgetStructure () {
            return this.sortedWidgetStructureWithData.filter((block)=>{
                const componentForWidgetExist = Object.prototype.hasOwnProperty.call(this.$options.components, block.name);
                const isWidget = (block?.type ?? 'widget') === 'widget';
                let canBeShowen = componentForWidgetExist && isWidget;
                if (isWidget){
                    const dataFilled = !Array.isArray((block?.data ?? []));
                    canBeShowen = canBeShowen && dataFilled
                }
                return canBeShowen
            })
        },

        sortedWidgetStructureWithData() {
            return ([].concat(this.microPageStructure)).sort(function (a, b) {
                return a.orderOnPage - b.orderOnPage
            })
        },
    },
    components: {
        SectionProduct,
        widget_main_page_banner,
        widget_keyword_component,
        content_product_categories,
        widget_visual_navigation,
        content_teaser_carousel,
        widget_teaser_card,
        widget_bestsellers,
        widget_novelty,
        widget_we_recommend,
        widget_recently_viewed,
        widget_choice_other_buyers,
        content_teaser_small,
        widget_keyword_component_main,
    }
}
