//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ResponsiveImage from "@/components/common/ResponsiveImage";
import CommonBtnText from "@/components/common/buttons/TextBtn";
export default {
    name: "Item",
    components: {CommonBtnText, ResponsiveImage},
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        hasItemInfo() {
            return this.item.title || this.item.subTitle
        }
    },
    methods:{
        sendClick(){
            this.$emit('click')
        },
        sendView(isVisible){
            isVisible && this.$emit('viewed')
        }
    }
}
