import { render, staticRenderFns } from "./SectionContainer.vue?vue&type=template&id=311e225d&scoped=true&"
import script from "./SectionContainer.vue?vue&type=script&lang=js&"
export * from "./SectionContainer.vue?vue&type=script&lang=js&"
import style0 from "./SectionContainer.vue?vue&type=style&index=0&id=311e225d&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "311e225d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonContainer: require('/opt/atlassian/pipelines/agent/build/components/common/CommonContainer.vue').default})
