//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Item from './Components/Item'
import widgetLayoutMixin from "@/mixins/widgetLayoutMixin";
export default {
    name: "Main",
    components: {Item},
    mixins: [widgetLayoutMixin],
    data: function () {
        return {
            dataToSendInGa: [],
            gaSendTimeout: null
        }
    },
    methods: {
        oneCardViewed(isVisible, entry, item, position) {
            if(!isVisible){
                return
            }
            this.dataToSendInGa.push(Object.assign({},item, {
                position
            }));
            this.gaSendTimeout && clearTimeout(this.gaSendTimeout);
            this.gaSendTimeout = setTimeout(()=>{
                this.$ga.viewPromotion((this?.dataToSendInGa ?? []).map((el) => {
                    let creative = (el?.title ?? '');
                    creative = creative !== '' ? creative : (el?.link?.text ?? '');
                    return {
                        name: this.widgetInfo.name,
                        id: this.widgetInfo.id,
                        creative: creative,
                        position: el.position
                    }
                }))
                this.dataToSendInGa = []
            },50)
        },
        oneCardClicked(itemInfo, position) {
            let creative = (itemInfo?.title ?? '');
            creative = creative !== '' ? creative : (itemInfo?.link?.text ?? '');
            this.$ga.clickPromotion([{
                name: this.widgetInfo.name,
                id: this.widgetInfo.id,
                creative: creative,
                position: position
            }])
        },
        onMouseUp(e, itemInfo, position) {
            if (e.button === 1){
                this.oneCardClicked(itemInfo, position)
            }
        }
    }
}
