//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ResponsiveImage from "@/components/common/ResponsiveImage";
import ResponsiveVideo from "@/components/common/ResponsiveVideo";
import CommonMedia from "@/components/common/CommonMedia";
import widgetLayoutMixin from "@/mixins/widgetLayoutMixin";

export default {
    name: "MainHomeBannerMain",
    components: {
        CommonMedia,
        ResponsiveVideo,
        ResponsiveImage
    },

    mixins: [widgetLayoutMixin],

    methods: {
        getButtonType(type) {
            return type === "secondary" ? "secondary-btn" : "main-btn";
        },
        isExternalLink(link) {
            return !!(link.includes('://'));
        },
        promotionViewed(isVisible, entry) {
            let creative = (this?.innerData?.title ?? '');
            creative = creative !== '' ? creative : (this?.innerData?.subtitle ?? '');
            creative = creative !== '' ? creative : (this?.innerData?.media?.title ?? '');
            isVisible && this.$ga.viewPromotion([
                {
                    name: this.widgetInfo.name,
                    id: this.widgetInfo.id,
                    creative: creative,
                    position: 1
                }
            ]);
        },
        promotionClicked(title) {
            let creative = title && title !== '' ? title : (this?.innerData?.title ?? '');
            creative = creative !== '' ? creative : (this?.innerData?.subtitle ?? '');
            creative = creative !== '' ? creative : (this?.innerData?.media?.title ?? '');
            this.$ga.clickPromotion([
                {
                    name: this.widgetInfo.name,
                    id: this.widgetInfo.id,
                    creative: creative,
                    position: 1
                }
            ]);
        },
        onMouseUp(e, title) {
            if (e.button === 1) {
                this.promotionClicked(title);
            }
        },
        getPositionClass(key) {
            if (typeof this.innerData.buttonsPosition !== "object") {
                return "";
            }
            let resultClass = "";

            if (this.innerData.buttonsPosition?.[key] == 0) {
                resultClass = `${ key }-start`;
            } else if (this.innerData.buttonsPosition?.[key] == 50) {
                resultClass = `${ key }-center`;
            } else if (this.innerData.buttonsPosition?.[key] == 100) {
                resultClass = `${ key }-end`;
            }

            return resultClass;
        }
    },

    computed: {
        isLightBanner() {
            return this.innerData.style === "light";
        },
        isSmall() {
            return this.innerData.type === "small";
        },
        isText() {
            return this.innerData.type === "text";
        },
        hasLogo() {
            return (this.innerData.logo?.src?.desktop?.['1x'] ?? false)
        }
    }
};
