//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mainLayout from "./layouts/Main";
import widgetMixin from "@/mixins/widgetMixin";
import SectionBackgroundContainer from "~/components/common/section/SectionBackgroundContainer";

export default {
    name: "CompilationCarousel",

    components: {
        mainLayout,
        SectionBackgroundContainer,
    },

    mixins: [widgetMixin],

};
