//
//
//
//
//
//
//
//
//
//
//
//
//

import ResponsiveImage from "@/components/common/ResponsiveImage";
import CommonBtnText from "@/components/common/buttons/TextBtn";
import CommonMedia from "@/components/common/CommonMedia";

export default {
    name: "Item",
    components: {CommonMedia, CommonBtnText, ResponsiveImage},
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        hasItemInfo() {
            return this.item.title || this.item.subTitle
        },
    }
}
