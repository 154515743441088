//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Carousel from "@/widgets/carousel/Index";
import SectionTitle from "@/components/common/section/SectionTitle";
import SectionSubtitle from "@/components/common/section/SectionSubtitle";
import Item from './Components/Item'
import CategoriesLists from "@/widgets/categoriesTabs/layouts/Components/CategoriesLists";
import CategoriesCarousel from "@/widgets/categoriesTabs/layouts/Components/CategoriesCarousel";
import widgetLayoutMixin  from "@/mixins/widgetLayoutMixin";
import breakpointsListMixin from '~/mixins/breakpointsList.mixin';

export default {
name: "Main",
    components: {CategoriesCarousel, CategoriesLists, SectionSubtitle, SectionTitle, Carousel, Item},
    mixins: [
        widgetLayoutMixin,
        breakpointsListMixin,
    ],
    data() {
        return {
            settings: {
                dots: false,
                variableWidth: true,
                infinite: false,
                slidesToShow: 4,
                slidesToScroll: 4,
                responsive: [
                    {
                        breakpoint: 1130,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    },
                    {
                        breakpoint: 820,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 590,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    },
                ]
            },
            nowTab: 0,
        }
    },
    computed: {
        componentView() {
            return this.tabletDown ? 'CategoriesLists' : 'CategoriesCarousel'
        },
        activeCategories() {
            const arr = Array.isArray(this.innerData) ? this.innerData : [this.innerData]
            return  arr.filter(i => Boolean(i?.items?.length));
        },
    },
    methods: {
        setActiveTab(index) {
            this.nowTab = index;
        }
    },
}
