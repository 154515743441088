//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Item from "@/widgets/categoriesTabs/layouts/Components/Item";
export default {
    name: "CategoriesLists",
    components: {Item},
    props: {
        items: {
            type: Array,
            required: true
        },
        categoryTitle: {
            type: String,
            default: ''
        },
        promoInfo: {
            type: Object,
            default: function () {
                return {
                    name: '',
                    id: ''
                }
            }
        }
    },
    computed: {
        lists() {
            return this.items.reduce(function(result, value, index, array) {
                if (index % 5 === 0)
                    result.push(array.slice(index, index + 5));
                return result;
            }, []);
        }
    },
    methods: {
        sendViewed(item, position) {
            this.dataToSendInGa?.push(Object.assign({}, item, {
                position
            }));
            this.gaSendTimeout && clearTimeout(this.gaSendTimeout);
            this.gaSendTimeout = setTimeout(() => {
                this.$ga.viewPromotion((this?.dataToSendInGa ?? []).map((el) => ({
                    name: this.promoInfo.name,
                    id: this.promoInfo.id,
                    creative: `${this.categoryTitle} ${(el?.link?.text ?? '')}`,
                    position: el.position
                })))
                this.dataToSendInGa = []
            }, 50)
        },
        sendClick(itemInfo, position) {
            this.$ga.clickPromotion([{
                name: this.promoInfo.name,
                id: this.promoInfo.id,
                creative: `${this.categoryTitle} ${(itemInfo?.link?.text ?? '')}`,
                position: position
            }])
        },
        onMouseUp(e, itemInfo, position) {
            if (e.button === 1){
                this.sendClick(itemInfo, position)
            }
        }
    }
}
