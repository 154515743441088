//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ResponsiveImage from "@/components/common/ResponsiveImage";
import Item from "./Components/Item";
import widgetLayoutMixin from "@/mixins/widgetLayoutMixin";
export default {
name: "Main",
    components: {ResponsiveImage, Item},
    mixins: [widgetLayoutMixin],
    data: function () {
        return {
            gaSendTimeout: null,
            dataToSendInGa: []
        }
    },
    computed: {
        sortList() {
            return (this?.innerData?.items ?? []);
        }
    },
    methods: {
        oneCardViewed(isVisible, entry, item, position) {
            if(!isVisible){
                return
            }
            this.dataToSendInGa.push(Object.assign({},item, {
                position
            }));
            this.gaSendTimeout && clearTimeout(this.gaSendTimeout);
            this.gaSendTimeout = setTimeout(()=>{
                this.$ga.viewPromotion((this?.dataToSendInGa ?? []).map((el) => {
                    let creative = (el?.link?.text ?? '');
                    creative = creative !== '' ? creative : (this?.innerData?.title ?? '');
                    return {
                        name: this.widgetInfo.name,
                        id: this.widgetInfo.id,
                        creative: creative,
                        position: el.position
                    }
                }))
                this.dataToSendInGa = []
            },50)
        }
    }
}
