//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ResponsiveImage from "@/components/common/ResponsiveImage";
import SectionTitle from "@/components/common/section/SectionTitle";
import Item from "./Components/Item";
import widgetLayoutMixin from "@/mixins/widgetLayoutMixin";

export default {
    name: "ForWhom",
    components: {SectionTitle, Item},
    mixins: [widgetLayoutMixin],
    data: function () {
        return {
            gaSendTimeout: null,
            dataToSendInGa: []
        }
    },
    methods: {
        oneCardViewed(isVisible, entry, item, position) {
            if (!isVisible) {
                return
            }
            this.dataToSendInGa.push(Object.assign({}, item, {
                position
            }));
            this.gaSendTimeout && clearTimeout(this.gaSendTimeout);

            this.gaSendTimeout = setTimeout(() => {
                this.$ga.viewPromotion((this?.dataToSendInGa ?? []).map((el) => {
                    let creative = (el?.title ?? '');
                    creative = creative !== '' ? creative : (this?.innerData?.title ?? '');
                    return {
                        name: this.widgetInfo.name,
                        id: this.widgetInfo.id,
                        creative: creative,
                        position: el.position
                    }
                }))
                this.dataToSendInGa = []
            }, 50)
        },
        sendInGa(el) {
            let creative = (el?.title ?? '');
            creative = creative !== '' ? creative : (this?.innerData?.title ?? '');
            this.$ga.clickPromotion([{
                name: this.widgetInfo.name,
                id: this.widgetInfo.id,
                creative: creative,
                position: this.position
            }])
        },
        onMouseUp(e, card) {
            if (e.button === 1) {
                this.sendInGa(card)
            }
        }
    }
}
