//
//
//
//
//
//
//
//
//
//
//

import ResponsiveImage from "@/components/common/ResponsiveImage";
import CommonMedia from "@/components/common/CommonMedia";

export default {
    name: "Item",
    components: {CommonMedia, ResponsiveImage},
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        colorText() {
            return this.item?.link?.color ?? '#ffffff';
        }
    },
}
