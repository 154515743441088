//
//
//
//
//
//
//
//
//
//
//

import ResponsiveImage from "@/components/common/ResponsiveImage";
export default {
name: "Item",
    components: {ResponsiveImage},
    props: {
        item: {
            type: Object,
            required: true
        },
        widgetInfo: {
            type: Object,
            required: true
        },
        position: {
            type: Number,
            required: true
        },
    },
    methods:{
        sendInGa () {
            this.$ga.clickPromotion([{
                name: this.widgetInfo.name,
                id: this.widgetInfo.id,
                creative: (this.item?.link?.text),
                position: this.position
            }])
        },
        onMouseUp (e) {
            if (e.button) {
                this.sendInGa()
            }
        }
    }
}
