var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"for-whom"},[_c('section-title',[_vm._v(_vm._s(_vm.innerData.title))]),_vm._v(" "),_c('div',{staticClass:"for-whom__cards"},_vm._l((_vm.innerData.items),function(card,index){return _c('item',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
              callback: function (isVisible, entry) {_vm.oneCardViewed(isVisible, entry, card, index+1)},
              callback: _vm.oneCardViewed,
              once: true,
              intersection: {
                threshold: 0.55,
              },
            }),expression:"{\n              callback: (isVisible, entry) => {oneCardViewed(isVisible, entry, card, index+1)},\n              callback: oneCardViewed,\n              once: true,\n              intersection: {\n                threshold: 0.55,\n              },\n            }"}],key:index,staticClass:"for-whom__cards__item",attrs:{"item":card,"position":index+1,"widget-info":_vm.widgetInfo},nativeOn:{"mouseup":function($event){return _vm.onMouseUp($event, card)},"click":function($event){return _vm.sendInGa(card)}}})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }