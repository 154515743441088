//
//
//
//
//
//

import mainLayout from './layouts/Main'
import SectionFullWidth from "@/components/common/section/SectionFullWidth";
import widgetMixin from "@/mixins/widgetMixin";
export default {
    name: "TeaserCardSmall",
    components: {SectionFullWidth, mainLayout},
    mixins: [widgetMixin],
    data(){
      return{
         innerDataCopy: JSON.parse(JSON.stringify(this.widgetInfo))
      }
    },
    computed: {
        showedWidget() {
            return (this?.widgetInfo?.data?.items?.length ?? 0 ) >= 4;
        },
    }
}
