//
//
//
//
//
//
//
//
//
//
//
//
//

import Carousel from "@/widgets/carousel/Index";
import Item from "./Item";
import SlideCarousel from "@/components/common/slideCarousel/index";

export default {
    name: "CategoriesCarousel",
    components: {SlideCarousel, Item, Carousel},
    props: {
        items: {
            type: Array,
            required: true
        },
        categoryTitle: {
            type: String,
            default: ''
        },
        promoInfo: {
            type: Object,
            default: function () {
                return {
                    name: '',
                    id: ''
                }
            }
        }
    },
    data: function () {
        return {
            dataToSendInGa: [],
            gaSendTimeout: null
        }
    },
    computed: {
        itemsCarousel() {
            let arrayAll = [];
            this.items.forEach(i => {
                arrayAll = arrayAll.concat(i.items);
            })
            return arrayAll
        }
    },
    methods: {
        sendViewed(item, position) {
            this.dataToSendInGa.push(Object.assign({}, item, {
                position
            }));
            this.gaSendTimeout && clearTimeout(this.gaSendTimeout);
            this.gaSendTimeout = setTimeout(() => {
                this.$ga.viewPromotion((this?.dataToSendInGa ?? []).map((el) => ({
                    name: this.promoInfo.name,
                    id: this.promoInfo.id,
                    creative: `${this.categoryTitle} ${(el?.link?.text ?? '')}`,
                    position: el.position
                })))
                this.dataToSendInGa = []
            }, 50)
        },
        sendClick(itemInfo, position) {
            this.$ga.clickPromotion([{
                name: this.promoInfo.name,
                id: this.promoInfo.id,
                creative: `${this.categoryTitle} ${(itemInfo?.link?.text ?? '')}`,
                position: position
            }])
        },
        onMouseUp(e, itemInfo, position) {
            if (e.button === 1){
                this.sendClick(itemInfo, position)
            }
        }
    }
}
