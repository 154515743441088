//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CommonMedia from "@/components/common/CommonMedia";
import CommonBtnText from "@/components/common/buttons/TextBtn";

export default {
    name: "Item",
    components: {
        CommonMedia,
        CommonBtnText,
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        hasLink() {
            return typeof this.item.link !== Array && !!this.item.link.href
        }
    }
}
